import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';
import { DocumentRenderer } from '@keystone-6/document-renderer';
import Card from '../sections/Blog/Card';
import Tag from '../sections/Blog/Tag';
import BlogSeo from '../components/BlogSeo';
import BackIcon from '../images/back_icon.svg';
import RootLayout from '../layouts/RootLayout';

const Container = styled.main`
    margin: 0 auto;
    padding: 90px 0 100px;

    @media screen and (max-width: 500px) {
        padding: 20px 0;
    }
`;

const MainWrapper = styled.div``;

const TagGroup = styled.div`
    margin-bottom: 10px;
`;

const HeadingSection = styled.div`
    max-width: 900px;
    margin: 0 auto;
    padding: 0 24px;
`;

const Heading = styled.h1`
    font-size: 36px;
    font-weight: 500;
    color: #000;
    margin-bottom: ${({ mb }) => (mb ? '36px' : 'none')};
    overflow-wrap: break-word;
    hyphens: manual;
`;

const BodyText = styled.p`
    margin-top: 16px;
    font-size: 16px;
    line-height: 150%;
    color: #2e2c4d;

    &:empty {
        height: 24px;
    }
`;

const CoverImg = styled.img`
    width: 100%;
    height: 600px;
    object-fit: cover;

    @media screen and (max-width: 500px) {
        height: 300px;
    }
`;

const SubHeadingSection = styled.div`
    max-width: 900px;
    margin: 0 auto;
    padding: 0 24px;
`;

const NextBlogWrapper = styled.div`
    max-width: 1080px;
    margin: 54px auto 80px;
    padding: 0 24px;

    @media screen and (max-width: 500px) {
        margin: 54px auto 40px;
    }
`;

const Line = styled.hr`
    border: none;
    border-top: 1px solid #e5e7eb;
    margin-bottom: 36px;
`;

// Document renderers style

const List = styled.div`
    margin-top: 16px;
    color: #2e2c4d;
`;

const ListItem = styled.li`
    margin-left: 40px;
    margin-top: 16px;
`;

const DocHeading = styled.h1``;

const DocsImg = styled.img`
    margin: 36px 0;

    @media screen and (max-width: 500px) {
        max-width: 300px;
    }
`;

const BtnDiv = styled.div`
    margin: 0 0 24px 24px;
    display: none;

    @media screen and (max-width: 500px) {
        display: block;
    }
`;

const BackBtn = styled.button`
    border: none;
    border-radius: 25px;
    padding: 4px 10px;
    background: #ebebeb;
    color: #484848;
    font-size: 12px;
    line-height: 14px;
    font-family: 'Rubik';
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
`;

// Article page query

export const query = graphql`
    query ArticlePage($id: ID) {
        cmsV1 {
            post(where: { id: $id }) {
                id
                title
                summary
                status
                slug
                heroImage {
                    url
                    srcSet
                }
                tags {
                    name
                    id
                    rgbColor
                }
                content {
                    document
                }
                nextBlog {
                    id
                    summary
                    title
                    slug
                    heroImage {
                        url
                        srcSet
                    }
                    tags {
                        name
                        id
                        rgbColor
                    }
                }
            }
        }
    }
`;

function ArticlePage({ data }) {
    const { title, summary, tags, content, nextBlog, heroImage, slug } = data.cmsV1.post;

    const renderers = {
        block: {
            heading: ({ level, children, textAlign }) => {
                return <DocHeading as={`h${level}`} style={{ textAlign }} children={children} />;
            },
            paragraph: ({ children, textAlign }) => {
                return <BodyText style={{ textAlign }}>{children}</BodyText>;
            },
            list: ({ children, type }) => {
                return (
                    <List as={type === 'ordered' ? 'ol' : 'ul'}>
                        {children.map((item, id) => (
                            <ListItem key={id}>{item}</ListItem>
                        ))}
                    </List>
                );
            },
        },
    };

    const componentBlockRenderers = {
        files: ({ files }) => {
            return (
                <div style={{ textAlign: 'center' }}>
                    <DocsImg
                        src={`https://cdn.authenticating.com/public/cms/${files}`}
                        alt="document banner"
                        loading="lazy"
                    />
                </div>
            );
        },
    };

    return (
        <>
            <BlogSeo
                title={`${title} | Authenticate Blog`}
                description={summary}
                tags={tags.map((tag) => tag.name).toString()}
                pageType="single blog"
                slug={slug}
            />
            <RootLayout>
                <Container id="resources">
                    <BtnDiv>
                        <BackBtn onClick={() => navigate(-1)}>
                            <img src={BackIcon} alt="Back Icon" loading="lazy" /> Back
                        </BackBtn>
                    </BtnDiv>
                    <MainWrapper>
                        <HeadingSection>
                            <TagGroup>
                                {tags.map((tag) => {
                                    return (
                                        <Tag key={tag.id} colorTag={tag.rgbColor}>
                                            {tag.name}
                                        </Tag>
                                    );
                                })}
                            </TagGroup>
                            <Heading>{title}</Heading>
                            <BodyText>{summary}</BodyText>
                        </HeadingSection>
                        <SubHeadingSection>
                            <CoverImg
                                src={heroImage.url}
                                srcSet={heroImage.srcSet}
                                alt="Cover Image of blog"
                            />
                            <DocumentRenderer
                                document={content.document}
                                renderers={renderers}
                                componentBlocks={componentBlockRenderers}
                            />
                        </SubHeadingSection>
                    </MainWrapper>
                    <>
                        {nextBlog === null ? null : (
                            <NextBlogWrapper>
                                <Line />
                                <Heading mb>Up next</Heading>
                                <Card
                                    next
                                    id={nextBlog.id}
                                    title={nextBlog.title}
                                    summary={nextBlog.summary}
                                    blogImage={nextBlog.heroImage.url}
                                    srcSet={nextBlog.heroImage.srcSet}
                                    tags={nextBlog.tags}
                                    slug={nextBlog.slug}
                                />
                            </NextBlogWrapper>
                        )}
                    </>
                    <BtnDiv>
                        <BackBtn onClick={() => navigate(-1)}>
                            <img src={BackIcon} alt="Back Icon" loading="lazy" /> Back
                        </BackBtn>
                    </BtnDiv>
                </Container>
            </RootLayout>
        </>
    );
}

ArticlePage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ArticlePage;
