import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import Tag from "../../sections/Blog/Tag";

const Container = styled.div`
	/* max-width: 500px; */
	overflow: hidden;
	width: 100%;

	${({ first }) =>
		first &&
		css`
			@media screen and (max-width: 768px) {
				background-image: linear-gradient(
						to top,
						rgba(0, 0, 0, 0.85),
						transparent
					),
					url(${(props) => props.blogImage});
				background-size: cover;
				background-position: top;
				padding: 10px;
				border-radius: 15px;
			}
		`}
`;
const CardLink = styled(Link)`
	text-decoration: none;

	${({ first }) =>
		first &&
		css`
			order: -1;
			@media screen and (min-width: 768px) {
				grid-column: span 2;
				margin-bottom: 42px;
			}
		`}
`;

const CardWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	/* grid-template-rows: 1fr 1fr; */

	${({ first }) =>
		first &&
		css`
			@media screen and (min-width: 768px) {
				grid-template-columns: 2fr 1fr;
				grid-template-rows: 1fr;
				gap: 44px;
				align-items: center;
			}
		`}

	${({ next }) =>
		next &&
		css`
			@media screen and (min-width: 768px) {
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 1fr;
				gap: 36px;
				align-items: center;
			}
		`}
`;

const CardImage = styled.div``;

const CoverImg = styled.img`
	width: 100%;
	border-radius: 4px;
	height: 350px;
	object-fit: cover;

	@media screen and (max-width: 768px) {
		height: 200px;
	}

	${({ first }) =>
		first &&
		css`
			@media screen and (min-width: 768px) {
				height: 450px;
			}

			@media screen and (max-width: 768px) {
				display: none;
			}
		`}

	${({ next }) =>
		next &&
		css`
			height: 250px;
		`}
`;

const CardBody = styled.div`
	margin-top: 16px;

	${({ first }) =>
		first &&
		css`
			@media screen and (min-width: 768px) {
				margin-top: 0;
			}

			@media screen and (max-width: 768px) {
				margin-top: 0;
			}
		`}

	${({ next }) =>
		next &&
		css`
			@media screen and (min-width: 768px) {
				margin-top: 0;
			}
		`}
`;

const TagGroup = styled.div`
	margin-bottom: 8px;

	${({ first }) =>
		first &&
		css`
			@media screen and (min-width: 768px) {
				margin-bottom: 10px;
			}

			@media screen and (max-width: 768px) {
				margin-bottom: 130px;
			}
		`}

	${({ next }) =>
		next &&
		css`
			margin-bottom: 10px;
		`}
`;

const Heading = styled.h1`
	font-size: 18px;
	font-weight: 500;
	color: #000;

	${({ first }) =>
		first &&
		css`
			@media screen and (min-width: 768px) {
				font-size: 36px;
			}

			@media screen and (max-width: 768px) {
				font-size: 16px;
				color: #fff;
				text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
			}
		`}
`;

const BodyText = styled.p`
	font-size: 14px;
	margin-top: 8px;
	line-height: 150%;
	color: #000;

	${({ first }) =>
		first &&
		css`
			@media screen and (max-width: 768px) {
				font-size: 12px;
				color: #d1d5db;
			}
		`}

	${({ next }) =>
		next &&
		css`
			margin-top: 16px;
		`}
`;

const BlogLink = styled(Link)`
	display: none;
	text-decoration: none;
	margin-top: 16px;
	padding: 8px 16px;
	font-size: 14px;
	color: #fff;
	background: #1ba1db;
	border-radius: 4px;
	transition: all 0.2ms;
	cursor: pointer;

	${({ next }) =>
		next &&
		css`
			display: inline-block;
		`}

	${({ first }) =>
		first &&
		css`
			@media screen and (min-width: 768px) {
				display: inline-block;
			}
		`}
`;

const TextSpan = styled.span`
	font-weight: 500;
	color: #154683;
	margin-left: 4px;

	${({ first }) =>
		first &&
		css`
			@media screen and (max-width: 768px) {
				color: #d1d5db;
			}
		`}
`;

function Card({
	first,
	next,
	title,
	summary,
	id,
	tags,
	blogImage,
	slug,
	srcSet
}) {
	const [isReadMore, setIsReadMore] = useState(true);

	const toggleReadMore = (e) => {
		e.preventDefault();
		setIsReadMore(!isReadMore);
	};

	return (
		<CardLink to={`/resources/blog/${slug}`} key={id} first={first}>
			<Container first={first} blogImage={blogImage}>
				<CardWrapper first={first} next={next}>
					<CardImage>
						<CoverImg
							first={first}
							next={next}
							src={blogImage}
							srcSet={srcSet}
							alt="Card Cover Image"
							loading="lazy"
						/>
					</CardImage>
					<CardBody first={first} next={next}>
						<TagGroup first={first} next={next}>
							{tags.map((tag) => {
								return (
									<Tag key={tag.id} colorTag={tag.rgbColor}>
										{tag.name}
									</Tag>
								);
							})}
						</TagGroup>
						<Heading first={first}>{title}</Heading>
						<BodyText first={first}>
							{isReadMore ? summary.slice(0, 102) : summary}
							<TextSpan onClick={toggleReadMore} first={first}>
								{isReadMore ? " ...read more" : " show less"}
							</TextSpan>
						</BodyText>
						<BlogLink
							first={first}
							next={next}
							to={`/resources/blog/${slug}`}
						>
							Read More &nbsp; {`>`}
						</BlogLink>
					</CardBody>
				</CardWrapper>
			</Container>
		</CardLink>
	);
}

export default Card;
