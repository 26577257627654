import React from "react";
import styled from "styled-components";

const Badge = styled.p`
	display: inline-block;
	font-size: 12px;
	padding: 2px 8px;
	border-radius: 2px;
	margin-right: 16px;
	color: #fff;
	background-color: ${(props) => props.colorTag};
`;

function Tag({ children, colorTag }) {
	return (
		<>
			<Badge colorTag={colorTag}>{children}</Badge>
		</>
	);
}

export default Tag;
